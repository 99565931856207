
var swiper_sub
var swiper_main

window.addEventListener('DOMContentLoaded', () => {
  swiper_sub = new Swiper('.swiper-sub', {
    centeredSlides: true,
    loop: true,
    loopAdditionalSlides: 1,
    slidesPerView: 5.4,
    spaceBetween: 24,
    threshold: 5,
  });
  swiper_main = new Swiper('.swiper-main', {
    centeredSlides: true,
    loop: true,
    loopAdditionalSlides: 5,
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
    slidesPerView: 1,
    spaceBetween: 40,
    breakpoints: {
      768: {
        slidesPerView: 1.5,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    thumbs: {
      swiper: swiper_sub,
    },
    autoplay: {
      delay: 3000,
    },
  });
  swiper_main.on('activeIndexChange', ()=>{
    swiper_sub.slideTo(swiper_main.activeIndex, 300, false)
    // ↑ slideToLoop()だとループ末の次に先頭までビューンと巻き戻ってしまうので slideTo() を利用。
    // 尚、slideTo() だと、要素数がメインとサブとで合っていないとJSエラーが起きたりするので、
    // それぞれの loopAdditionalSlides を調整して swiper_main.slides.length と swiper_sub.slides.length
    // が一致するようにした。この数一致はHTML側のスライド数が変わってもmainとsubが同じスライド数なら変更不要。
    // ※slidesPerView の値によって作られるスライド数が違ってくる模様。
  })
  swiper_main.on('autoplayStop', ()=>{
    swiper_main.autoplay.start()
  })
});
